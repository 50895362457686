@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  [type="text"]:focus,
  input:where(:not([type])):focus,
  [type="email"]:focus,
  [type="url"]:focus,
  [type="password"]:focus,
  [type="number"]:focus,
  [type="date"]:focus,
  [type="datetime-local"]:focus,
  [type="month"]:focus,
  [type="search"]:focus,
  [type="tel"]:focus,
  [type="time"]:focus,
  [type="week"]:focus,
  [multiple]:focus,
  textarea:focus,
  select:focus {
    --tw-ring-shadow: 0 0 #000 !important;
  }

  [type="checkbox"]:focus,
  [type="radio"]:focus {
    --tw-ring-shadow: 0 0 #000 !important;
  }
}

:root {
  --blue-casa-doc: 0 17 200; // rgb(0, 17, 200); //#0011c8;
  --font-family: "Inter", sans-serif;
}

* {
  box-sizing: border-box;
  outline: none;
}
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: var(--font-family);
}

app-root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  @apply bg-gray-50;
}

button:disabled,
button[disabled] {
  @apply cursor-not-allowed opacity-50;
}

.btn-solid {
  @apply inline-flex justify-center rounded-lg py-2 px-3 text-sm font-semibold outline-2 outline-offset-2 transition-colors cursor-pointer;
}

.btn-outline {
  @apply inline-flex justify-center rounded-lg border py-2 px-3 text-sm outline-2 outline-offset-2 transition-colors cursor-pointer;
}

/* Variant styles for solid buttons */

.btn-solid-blue-casa-doc {
  @apply btn-solid relative overflow-hidden bg-blue-casa-doc text-white  bg-opacity-100 hover:bg-opacity-90 active:text-white/80 transition-colors;
}

.btn-solid-cyan {
  @apply btn-solid relative overflow-hidden bg-cyan-500 text-white hover:bg-cyan-600 active:text-white/80 transition-colors;
}

.btn-solid-white {
  @apply btn-solid bg-white text-cyan-900 hover:bg-white/90 active:text-cyan-900/70 transition-colors;
}

.btn-solid-gray {
  @apply btn-solid bg-gray-800 text-white hover:bg-gray-900 active:text-white/80 transition-colors;
}

/* Variant styles for outline buttons */
.btn-outline-gray {
  @apply btn-outline border-gray-300 text-gray-700 hover:border-gray-400 active:bg-gray-100 active:text-gray-700/80 transition-colors;
}
